enum EventsType {
    SEARCH = 'SEARCH',
    RELOAD = 'RELOAD',
    CREATE = 'CREATE',

    SEARCH_WIZARD = 'SEARCH_WIZARD',

    SEARCH_BY_PRODUCT_ANALYSIS_METHOD = 'SEARCH_BY_PRODUCT_ANALYSIS_METHOD',
    SEARCH_BY_PRODUCT_ANALYSIS_REPORT = 'SEARCH_BY_PRODUCT_ANALYSIS_REPORT',
    SEARCH_BY_PRODUCT_ANALYSIS_VALUE = 'SEARCH_BY_PRODUCT_ANALYSIS_VALUE',

    SEARCH_BY_PRODUCT_ANALYSIS_METHOD_CODE = 'SEARCH_BY_PRODUCT_ANALYSIS_METHOD_CODE',
    SEARCH_BY_PRODUCT_ANALYSIS_TYPE = 'SEARCH_BY_PRODUCT_ANALYSIS_TYPE',
    SEARCH_BY_PRODUCT_ANALYSIS_REPORT_ID = 'SEARCH_BY_PRODUCT_ANALYSIS_REPORT_ID',
    SEARCH_BY_PRODUCT_ANALYSIS_SAMPLE_ID = 'SEARCH_BY_PRODUCT_ANALYSIS_SAMPLE_ID',
    SEARCH_BY_PRODUCT_ANALYSIS_REPORT_SOURCE = 'SEARCH_BY_PRODUCT_ANALYSIS_REPORT_SOURCE',
    SEARCH_BY_PRODUCT_ANALYSIS_CLASS = 'SEARCH_BY_PRODUCT_ANALYSIS_CLASS',
    SEARCH_BY_PRODUCT_ANALYSIS_PRODUCT = 'SEARCH_BY_PRODUCT_ANALYSIS_PRODUCT',


    SEARCH_BY_COMPOUND = 'SEARCH_BY_COMPOUND',
    SEARCH_BY_CANNABINOID = 'SEARCH_BY_CANNABINOID',
    SEARCH_BY_STRAIN = 'SEARCH_BY_STRAIN',
    SEARCH_BY_METABOLITE = 'SEARCH_BY_METABOLITE',
    SEARCH_BY_REFERENCE = 'SEARCH_BY_REFERENCE',
    SEARCH_BY_STUDY_METABOLITE = 'SEARCH_BY_STUDY_METABOLITE',
    SEARCH_BY_STUDY_PK = 'SEARCH_BY_STUDY_PK',
    SEARCH_BY_STUDY_TI = 'SEARCH_BY_STUDY_TI',
    SEARCH_BY_STUDY = 'SEARCH_BY_STUDY',
    SEARCH_BY_STUDY_PK_INDICES = 'SEARCH_BY_STUDY_PK_INDICES',

    SEARCH_BY_TERPENE = 'SEARCH_BY_TERPENE',
    SEARCH_BY_FLAVONOID = 'SEARCH_BY_FLAVONOID',
    SEARCH_BY_PHENOL = 'SEARCH_BY_PHENOL',
    SEARCH_BY_AROMA = 'SEARCH_BY_AROMA',
    SEARCH_BY_PHYSICAL_PROPERTIES_CBD = 'SEARCH_BY_PHYSICAL_PROPERTIES_CBD',
    SEARCH_BY_PHARMACO_DYNAMICS = 'SEARCH_BY_PHARMACO_DYNAMICS',

    SEARCH_BY_REFERENCE_FIELD = 'SEARCH_BY_REFERENCE_FIELD',
    SEARCH_STRAIN_BY_CONCENTRATION = 'SEARCH_STRAIN_BY_CONCENTRATION',

    SEARCH_BY_PUBCHEM_CID = 'SEARCH_BY_PUBCHEM_CID',
    SEARCH_BY_PUBCHEM_SID = 'SEARCH_BY_PUBCHEM_SID',
    SEARCH_BY_INCHIKEY = 'SEARCH_BY_INCHIKEY',
    SEARCH_BY_CAS = 'SEARCH_BY_CAS',

    SEARCH_NCT_ID = 'SEARCH_NCT_ID',
    SEARCH_BY_CBD_ID = 'SEARCH_BY_CBD_ID',
    SEARCH_BY_CBD_DRUG = 'SEARCH_BY_CBD_DRUG',
    SEARCH_BY_CBD_MARKET_DRUG = 'SEARCH_BY_CBD_MARKET_DRUG',

    SEARCH_BY_CSID = 'SEARCH_BY_CSID',


    SEND_PUBCHEM_COMPOUND = 'SEND_PUBCHEM_COMPOUND',

    BUCKET_CLONE = 'BUCKET_CLONE',
    BUCKET_ADD = 'BUCKET_ADD',
    BUCKET_RENAME = 'BUCKET_RENAME',
    BUCKET_RELOAD_OBJECT = 'BUCKET_RELOAD_OBJECT',
    BUCKET_DELETE_OBJECT = 'BUCKET_DELETE_OBJECT',
    BUCKET_DELETE_ALL = 'BUCKET_DELETE_ALL',
    BUCKET_TO_DELETE = 'BUCKET_TO_DELETE',


    LOADING = 'LOADING',
    LOADED = 'LOADED',

    HAS_NEW_DATA = 'HAS_NEW_DATA',
    CLEAR_ALL_DATA = 'CLEAR_ALL_DATA',

}

export { EventsType }
