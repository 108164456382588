//
// Cannabinoids
//

export interface iCannabinoid {
    COMPOUND_NAME: string;
    INCHIKEY: string;
    SMILES: string;
    FAMILY_TYPE_NAME: string;
    FAMILY_TYPE_CODE: string;
    REFERENCE_LEGEND_ARTICLE: string;
    ABBREVIATION: string;
    ISOLATED: string;
    ISOLATED_FROM: string;
    STRUCTURE_ELUCIDATION: string;
    COMMENT: string;
    REF_ARTICLE: string;
    CBD_ID: string;
    PUBCHEM_CID: string;

}

export const Cannabinoid: iCannabinoid = {
    COMPOUND_NAME: '',
    INCHIKEY: '',
    SMILES: '',
    FAMILY_TYPE_NAME: '',
    FAMILY_TYPE_CODE: '',
    REFERENCE_LEGEND_ARTICLE: '',
    ABBREVIATION: '',
    ISOLATED: '',
    ISOLATED_FROM: '',
    STRUCTURE_ELUCIDATION: '',
    COMMENT: '',
    REF_ARTICLE: '',
    CBD_ID: '',
    PUBCHEM_CID: ''
}


//
// PharmacoDynamics
//

export interface iPharmacoDynamics {
   INCHIKEY: string;
   COMPOUND_NAME: string;
   SMILES_CANONICAL: string;
   SMILES_ISOMERIC: string;
   CAS: string;
    MEASURE_TYPE: string;
    MEASURE_RELATION: string;
    MEASURE_VALUE: string;
    MEASURE_UNITS: string;
    P_VALUE: string;
    DATA_VALIDITY_COMMENT: string;
    COMMENT: string;
    ASSAY_DESCRIPTION: string;
    ASSAY_ORGANISM: string;
    ASSAY_CELL_TYPE: string;
    ASSAY_SUBCELL_FRACTION: string;
    ASSAY_TISSUE: string;
    ASSAY_TYPE: string;
    CONCENTRATION_TEST_COMPOUND: string;
    UNITS_CONCENTRATION_TEST_COMPOUND: string;
    LIGAND_OR_SUBSTRATE: string;
    TARGET_NAME: string;
    TARGET_ORGANISM: string;
    TARGET_TYPE: string;
    PROPERTIES: string;
    ACTION_TYPE: string;
    SOURCE_DESCRIPTION: string;
    REFERENCE_PMID: string;
    REFERENCE_ID: string;
    REFERENCE_TITLE: string;
    REFERENCE_AUTHORS: string;
    REFERENCE_JOURNAL: string;
    REFERENCE_DOI: string;
}


export const PharmacoDynamics: iPharmacoDynamics = {
    INCHIKEY: '',
    COMPOUND_NAME: '',
    SMILES_CANONICAL: '',
    SMILES_ISOMERIC: '',
    CAS: '',
    MEASURE_TYPE: '',
    MEASURE_RELATION: '',
    MEASURE_VALUE: '',
    MEASURE_UNITS: '',
    P_VALUE: '',
    DATA_VALIDITY_COMMENT: '',
    COMMENT: '',
    ASSAY_DESCRIPTION: '',
    ASSAY_ORGANISM: '',
    ASSAY_CELL_TYPE: '',
    ASSAY_SUBCELL_FRACTION: '',
    ASSAY_TISSUE: '',
    ASSAY_TYPE: '',
    CONCENTRATION_TEST_COMPOUND: '',
    UNITS_CONCENTRATION_TEST_COMPOUND: '',
    LIGAND_OR_SUBSTRATE: '',
    TARGET_NAME: '',
    TARGET_ORGANISM: '',
    TARGET_TYPE: '',
    PROPERTIES: '',
    ACTION_TYPE: '',
    SOURCE_DESCRIPTION: '',
    REFERENCE_PMID: '',
    REFERENCE_ID: '',
    REFERENCE_TITLE: '',
    REFERENCE_AUTHORS: '',
    REFERENCE_JOURNAL: '',
    REFERENCE_DOI: ''
}
//
// Strains
//

export interface iStrain {
    VARIETY_NAME: string;
    LAB_ID:string;
    ANONYMOUS_PRODUCER: string;
    MAIN_PURPOSE: string;
    COUNTRY: string;
    COUNTRY_REGION: string;
    PRODUCT_CATEGORY: string;
    STRAIN_CATEGORY: string;
    STRAIN_POPULARITY: string;
    CHEMOTYPE: string;
    CHEMOTYPE_RATIO: string;
    TOTAL_THC: string;
    TOTAL_CBD: string;
    TOTAL_CBG: string;
    TOTAL_CBC: string;
    TOTAL_CBN: string;
    TOTAL_THCV: string;
    TOTAL_TERPENES: string;
    TERPENES_VAR: string;
    DIOECIOUS_OR_MONOECIOUS: string;
    DESCRIPTORS_AND_GROWTH: string;
    PROPERTIES: string;
    SOURCE: string;
    NOTES: string;
    MAINTAINER_N_LIST: string;
    MAINTAINER: string;
    VARIETY_STATUS: string;
    REGISTRATION_DATE: string;
    GMO: string;
    PMA: string;
    VARIETY_ANOTHER_NAME: string;
    HYBRID: string;
    STRAIN_ID: string;
}

export const Strain: iStrain = {
    VARIETY_NAME: '',
    LAB_ID: '',
    ANONYMOUS_PRODUCER: '',
    MAIN_PURPOSE: '',
    COUNTRY: '',
    COUNTRY_REGION: '',
    PRODUCT_CATEGORY: '',
    STRAIN_CATEGORY: '',
    STRAIN_POPULARITY: '',
    CHEMOTYPE: '',
    CHEMOTYPE_RATIO: '',
    TOTAL_THC: '',
    TOTAL_CBD: '',
    TOTAL_CBG: '',
    TOTAL_CBC: '',
    TOTAL_CBN: '',
    TOTAL_THCV: '',
    TOTAL_TERPENES: '',
    TERPENES_VAR: '',
    DIOECIOUS_OR_MONOECIOUS: '',
    DESCRIPTORS_AND_GROWTH: '',
    PROPERTIES: '',
    SOURCE: '',
    NOTES: '',
    MAINTAINER_N_LIST: '',
    MAINTAINER: '',
    VARIETY_STATUS: '',
    REGISTRATION_DATE: '',
    GMO: '',
    PMA: '',
    VARIETY_ANOTHER_NAME: '',
    HYBRID: '',
    STRAIN_ID: ''
}

export interface iConcCompoundsByStrains {

    INCHIKEY: string;
  //  STRAIN_ID: string;
    CONCENTRATION: string;

}

export const ConcCompoundsByStrains: iConcCompoundsByStrains = {

    INCHIKEY: '',
  //  STRAIN_ID: '',
    CONCENTRATION: ''

}

//
// Terpenes
//

export interface iTerpene {
    TERPENE_TYPE : string;
    TERPENE_SUBTYPE: string;
    INCHIKEY: string;
    AROMA: string;
    ISOLATED_YEAR: string;
    ISOLATED_FROM: string;
    REF_ARTICLE: string;
}

export const Terpene: iTerpene = {
    TERPENE_TYPE : '',
    TERPENE_SUBTYPE: '',
    INCHIKEY: '',
    AROMA: '',
    ISOLATED_YEAR: '',
    ISOLATED_FROM: '',
    REF_ARTICLE: ''
}

//
// Flavonoids
//


export interface iFlavonoid {
    FLAVONOIDS_TYPE : string;
    FLAVONOIDS_SUBTYPE: string;
    INCHIKEY: string;
    AROMA: string;
    ISOLATED_YEAR: string;
    ISOLATED_FROM: string;
    REF_ARTICLE: string;
}

export const Flavonoid: iFlavonoid = {
    FLAVONOIDS_TYPE : '',
    FLAVONOIDS_SUBTYPE: '',
    INCHIKEY: '',
    AROMA: '',
    ISOLATED_YEAR: '',
    ISOLATED_FROM: '',
    REF_ARTICLE: ''
}


//
// Phenols
//

export interface iPhenols {
    PHENOLS_TYPE : string;
    PHENOLS_SUBTYPE: string;
    INCHIKEY: string;
    AROMA: string;
    ISOLATED_YEAR: string;
    ISOLATED_FROM: string;
    REF_ARTICLE: string;
}

export const Phenols: iPhenols = {
    PHENOLS_TYPE : '',
    PHENOLS_SUBTYPE: '',
    INCHIKEY: '',
    AROMA: '',
    ISOLATED_YEAR: '',
    ISOLATED_FROM: '',
    REF_ARTICLE: ''
}


//
// Aroma
//

export interface iAroma {
    INCHIKEY: string;
    COMPOUND_NAME: string;
    AROMA_TYPE: string;
    CHEMICAL_TYPE: string;
    COMMENT: string;
    PRED_LOGP_XLOGP3: string;
    PRED_LOGP_ACDLABS: string;
    BP: string;
    BP_MEDIAN: string;
    BP_PREDICTED: string;
    HEAT_OF_VAPORIZATION: string;
    PREDICTED_ENTHALPY_VAPORIZATION: string;
    VAPOR_PRESSURE: string;
    PREDICTED_VAPOR_PRESSURE: string;
    ODOR: string;
    ODOR_FROM_FLAVORNET: string;
    LINK: string;
    ODOR_THRESHOLD: string;
    TASTE: string;
}

export const Aroma: iAroma = {
    INCHIKEY: '',
    COMPOUND_NAME: '',
    AROMA_TYPE: '',
    CHEMICAL_TYPE: '',
    COMMENT: '',
    PRED_LOGP_XLOGP3: '',
    PRED_LOGP_ACDLABS: '',
    BP: '',
    BP_MEDIAN: '',
    BP_PREDICTED: '',
    HEAT_OF_VAPORIZATION: '',
    PREDICTED_ENTHALPY_VAPORIZATION: '',
    VAPOR_PRESSURE: '',
    PREDICTED_VAPOR_PRESSURE: '',
    ODOR: '',
    ODOR_FROM_FLAVORNET: '',
    LINK: '',
    ODOR_THRESHOLD: '',
    TASTE:'',
}

//
// CBD Market
//

export interface iMarket_CBD {
    DRUG_ID: string;
    DRUG_NAME: string;
    ACTIVE_INGREDIENT: string;
    THERAPEUTICAL_INDICATION: string;
}

export const Market_CBD : iMarket_CBD = {
    DRUG_ID: '',
    DRUG_NAME: '',
    ACTIVE_INGREDIENT: '',
    THERAPEUTICAL_INDICATION: ''
}

export interface iPhysical_characterization_CBD {
    SUPPLIER:  string;
    SUBSTANCE:  string;
    INCHIKEY: string;
    PRODUCT_RM_NUMBER:  string;
    LOT_NUMBER: string;
    SSA_BET_ANALYSIS_M2_G: string;
    PARTICLE_SIZE_DISTRIBUTION_D10: string;
    PARTICLE_SIZE_DISTRIBUTION_D50: string;
    PARTICLE_SIZE_DISTRIBUTION_D90: string;
    DOMINANT_PARTICLE_SHAPE:  string;
    MELTING_POINT_APPARATUS: string;
    MELTING_POINT_DSC:  string;
    TG:  string;
    CRYSTALLINITY:  string;
}

export const Physical_characterization_CBD : iPhysical_characterization_CBD = {
    SUPPLIER: '',
    SUBSTANCE: '',
    INCHIKEY: '',
    PRODUCT_RM_NUMBER: '',
    LOT_NUMBER: '',
    SSA_BET_ANALYSIS_M2_G: '',
    PARTICLE_SIZE_DISTRIBUTION_D10: '',
    PARTICLE_SIZE_DISTRIBUTION_D50: '',
    PARTICLE_SIZE_DISTRIBUTION_D90: '',
    DOMINANT_PARTICLE_SHAPE: '',
    MELTING_POINT_APPARATUS: '',
    MELTING_POINT_DSC: '',
    TG: '',
    CRYSTALLINITY: ''
}
