import React, {useEffect} from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import {FLAVONOIDS, getObjectsByRegEx, save, deleteDoc, getObjectByDoc } from '../../../api/Compounds';
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {Flavonoid} from "./types";
import { renderFields } from "../../../utils/pair_entry";
import FormEditModal from "../../common/FormEditModal";
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import {isNotEmpty} from "../../../utils/string";
import {useRoles} from "../../../App.Context";

const _FlavonoidCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data , searchBy, searchByDoc, isActive}) => {

    const UUID = '_FlavonoidCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACK

    const saveData = async ( data: any) => {
        save(FLAVONOIDS, data).then((res) => {
            setData(res);
            setIsShow(true);
        }).catch(setError);
    }

    const deleteData = async (data: any) => {
        deleteDoc(FLAVONOIDS, data).then((res) => {
            setData({});
            setIsShow(false);
        }).catch(setError);
    }


    const undo = async (undoData:any) => {
        Object.assign(data, undoData);
        save(FLAVONOIDS, data).then((res) => {
            setData(res);
            setIsShow(true);
        }).catch(setError);
    }

    const createEvent = (event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    };

    // ----- EVENTS

    const searchEvent = (event: { detail: string }) => {
        if (isActive) {
            searchBy?.(getObjectsByRegEx,FLAVONOIDS,  'COMPOUND_NAME,FLAVONOIDS_TYPE,FLAVONOIDS_SUBTYPE,AROMA,ISOLATED_FROM', event.detail);
        }
    }
    const searchByInchiKeyEvent = (event: { detail: string }) => {
        if (isActive) {
            searchBy?.(getObjectsByRegEx,FLAVONOIDS, 'INCHIKEY', event.detail);
        }
    }

    const searchByCompoundEvent = (event: { detail: any }) => {
        if (isActive) {
            searchByDoc?.(getObjectByDoc,FLAVONOIDS, event.detail, true, 'COMPOUND_NAME');
        }
    }


    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_INCHIKEY, searchByInchiKeyEvent, UUID);
        Events.on(EventsType.SEARCH_BY_TERPENE, searchByCompoundEvent, UUID);

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_BY_TERPENE, UUID);

        };
    }, [isActive]);

    // RENDERS --------------

    return(
        <p>
            {renderFields(Flavonoid, data)}

            { !hasReadOnlyRole && (
                <>
                    <FormEditModal
                        save={saveData}
                        DATATYPE={FLAVONOIDS}
                        data={data}
                        schema={Flavonoid}/>

                    <DisplayDeleteModal DATATYPE={FLAVONOIDS}
                                        deleteDoc={deleteData}
                                        data={data}
                                        schema={Flavonoid}/>

                </>
            )}

            <AuditModal id={data._id} undo={undo}/>


        </p>
    );

};


const FlavonoidCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width,eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_FlavonoidCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger}/>;
};

export default React.memo(FlavonoidCard);
