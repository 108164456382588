import React, {useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/en-gb';

import 'react-tabs/style/react-tabs.css';
import './css/App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar';

import './css/style.css';
import './css/Card.css';


import CompoundCard from './components/PMI/Compound';
import CompoundsTable from './components/PMI/CompoundsTable';
import CannabinoidTable from "./components/PMI/CBD/CannabinoidsTable";
import CannabinoidCard from "./components/PMI/CBD/Cannabinoid";
import TherapeuticIndicatorsTableCard from "./components/PMI/Studies/TherapeuticalIndicatorsTable";
import PKTableCard from "./components/PMI/Studies/PKTable";
import StrainsTable from "./components/PMI/CBD/StrainsTable";
import TherapeuticalIndicatorCard from "./components/PMI/Studies/TherapeuticalIndicator";
import PKCard from "./components/PMI/Studies/PK";
import StrainCard from "./components/PMI/CBD/Strain";
import PubChemTableCard from "./components/externals/pubchem/PubchemTable";
import PubChemListCard from "./components/externals/pubchem/PubchemList";
import PubchemCard from "./components/externals/pubchem/Pubchem";
import MetabolitesTableCard from "./components/PMI/Studies/MetabolitesTable";
import PubchemClinicalTrialsTableCard from "./components/externals/pubchem/PubchemClinicalTrials";
import ReferenceCard from "./components/PMI/Reference";
import StrainsCompoundsConcentrations from "./components/PMI/CBD/StrainCompoundsConcentrations";
import ClinicalTrialsTableCard from "./components/externals/ClinicalTrialsTable";
import ClinicalTrialCard from "./components/externals/ClinicalTrial";
import CannabisDBCard from "./components/externals/CannabisDB";
import AllBaskets from "./components/basket/AllBaskets";


import ChangeLog from "./components/changeLog";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import MarkIcon from "./components/graphics/MarkIcon";
import {Button} from "@mui/material";
import Events from "./events";
import {EventsType} from "./events-types";
import {ContextMessage} from "./components/ContextMessage";
import MetaboliteCard from "./components/PMI/Studies/Metabolite";
import TerpeneCard from "./components/PMI/CBD/Terpene";
import CreateData from "./components/AddNewData";
import MarketCBDCard from "./components/PMI/CBD/MarketCBD";
import MarketCBDTable from "./components/PMI/CBD/MarketCBDTable";
import AuditTrailCard from "./components/AuditTrail";
import StadisticsCard from "./components/Stadistics";
import TerpeneTable from "./components/PMI/CBD/TerpeneTable";
import UploadsCard from "./components/common/Uploads";
import WizardSearch from "./components/common/wizards/WizardSearch";
import {
    Aroma,
    Cannabinoid,
    Market_CBD,
    Strain,
    Terpene,
    Flavonoid,
    Phenols,
    PharmacoDynamics,
    Physical_characterization_CBD
} from "./components/PMI/CBD/types";
import {
    Compound,
    Reference,
    ProductAnalysisMethod,
    ProductAnalysisValue,
    ProductAnalysisReport
} from "./components/PMI/types";
import {Metabolite, PK, PK_Indices, TherapeuticIndicator} from "./components/PMI/Studies/types";
import {
    AROMA,
    CANNABINOIDS,
    COMPOUND,
    MARKET_CBD,
    REFERENCES,
    TERPENES,
    FLAVONOIDS,
    PHENOLS,
    PHARMACO_DYNAMICS,
    PHYSICAL_CHARACTERIZATION_CBD
} from "./api/Compounds";
import {STRAINS} from "./api/Strains";
import {CBD_PK_INDICES, SC_PK, SC_THERAPEUTIC_INDICATIONS, STUDIES_METABOLITES} from "./api/Studies";
import {PRODUCTS_ANALYSIS_METHODS, PRODUCT_ANALYSIS_VALUES, PRODUCTS_ANALYSIS_REPORTS} from "./api/Products"
import {StorageProvider} from "./App.Context";
import {getCurrentUser, getCurrentUserNameAndRoles} from "./services/Cognito";
import ChemSpiderTableCard from "./components/externals/ChemSpiderTable";
import ChemSpiderCard from "./components/externals/ChemSpider";
import PKIndicesTableCard from "./components/PMI/Studies/PKIndicesTable";
import PKIndicesCard from "./components/PMI/Studies/PKIndices";
import PhysicalCharacterizationCBDTable from "./components/PMI/CBD/PhysicalCharacterizationCBDTable";
import PhysicalCharacterizationCard from "./components/PMI/CBD/PhysicalCharacterizationCBD";
import PharmacoDynamicsTable from "./components/PMI/CBD/PharmacoDynamicsTable";
import PharmacoDynamicsCard from "./components/PMI/CBD/PharmacoDynamics";
import AromaTable from "./components/PMI/CBD/AromaTable";
import AromaCard from "./components/PMI/CBD/Aroma";
import ProductAnalisysValueCard from "./components/PMI/ProductAnalysisValue";
import ProductAnalisysMethodCard from "./components/PMI/ProductsAnalysisMethod"
import ProductAnalysisValuesTable from "./components/PMI/ProductAnalysisValuesTable";
// import ProductsAnalysisMethodTable from "./components/PMI/ProductsAnalysisMethodTable"

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {Tooltip} from "@mui/material";
import ProductAnalisysReportCard from "./components/PMI/ProductAnalysisReport";
import ProductsAnalysisReportsTable from "./components/PMI/ProductAnalysisReportTable";
import ProductsAnalysisMethodTable from './components/PMI/ProductsAnalysisMethodTable';
import FlavonoidCard from "./components/PMI/CBD/Flavonoid";
import FlavonoidsTable from "./components/PMI/CBD/FlavonoidsTable";
import PhenolsTable from "./components/PMI/CBD/PhenolsTable";
import PhenolCard from "./components/PMI/CBD/Phenol";

function App() {

    const [ roles, setRoles ] = useState<string[]>([]);

    const [isReadOnly, setIsReadOnly] = useState(true);
    const [showChangeLog, setShowChangeLog] = useState(true);
    const handleHideChangeLog = () => {
        setShowChangeLog(false);
    };

    const listsOfObjects = {
        [`${AROMA}`]: Aroma,
        [`${CANNABINOIDS}`]: Cannabinoid,
        [`${COMPOUND}`]: Compound,
        [`${REFERENCES}`]: Reference,
        [`${STRAINS}`]: Strain,
        [`${TERPENES}`]: Terpene,
        [`${FLAVONOIDS}`]: Flavonoid,
        [`${PHENOLS}`]: Phenols,
        [`${SC_THERAPEUTIC_INDICATIONS}`]: TherapeuticIndicator,
        [`${MARKET_CBD}`]: Market_CBD,
        [`${PHYSICAL_CHARACTERIZATION_CBD}`]: Physical_characterization_CBD,
        [`${STUDIES_METABOLITES}`]: Metabolite,
        [`${SC_PK}`]: PK,
        [`${CBD_PK_INDICES}`]: PK_Indices,
        [`${PHARMACO_DYNAMICS}`]: PharmacoDynamics,
        [`${PRODUCTS_ANALYSIS_METHODS}`]: ProductAnalysisMethod,
        [`${PRODUCT_ANALYSIS_VALUES}`]: ProductAnalysisValue,
        [`${PRODUCTS_ANALYSIS_REPORTS}`]: ProductAnalysisReport,


    };

    function clearAll() {
        Events.trigger(EventsType.CLEAR_ALL_DATA, []);
    }

    // forcing the workflow of the user identification and roles
    const fetchCurrentUser = async () => {
        try {
            const {user, roles } = await getCurrentUserNameAndRoles();
            console.log('roles', roles);
            setRoles(roles);
            setIsReadOnly(roles.includes("ReadOnly"));
        } catch (error) {
            console.log('Error setting current user:', error);
        }
    };

    useEffect(() => {
        fetchCurrentUser();
    }, []);

  return (
      <StorageProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <div className="App">

            <Navbar />

            <ContextMessage>
                <Tabs forceRenderTabPanel={true}>
                    <TabList>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Compounds / Cannabinoids / Metabolites
                                <MarkIcon width={'10px'} name={'compounds'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Studies / References
                                <MarkIcon width={'10px'} name={'studies'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                External Databases
                                 <MarkIcon width={'10px'} name={'external'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Varieties / Strains
                                <MarkIcon width={'10px'} name={'strains'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Products
                                <MarkIcon width={'10px'} name={'products'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Baskets
                                <MarkIcon width={'10px'} name={'baskets'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Loggers / Audit Trail
                                <MarkIcon width={'10px'} name={'loggers'}/>
                            </span></Tab>
                    </TabList>

                    {/* PMI Compounds/Cannabinoids/Metabolites */}
                    <TabPanel forceRender={true}>
                        <div className="row" >
                            <div className="col-9 col-sm-9">
                                <CompoundsTable     header={'Compounds'}   eventGroupToTrigger={'compounds'}/>
                                <CannabinoidTable   header={'Cannabinoids'}  eventGroupToTrigger={'compounds'}/>
                                <MetabolitesTableCard header={'Metabolites'}  eventGroupToTrigger={'compounds'}/>
                                <TerpeneTable header={'Terpenes'}  eventGroupToTrigger={'compounds'}/>
                                <FlavonoidsTable header={'Flavonoids'}  eventGroupToTrigger={'compounds'}/>
                                <PhenolsTable header={'Phenols'}  eventGroupToTrigger={'compounds'}/>
                                <MarketCBDTable header={'CBD Market'}  eventGroupToTrigger={'compounds'}/>
                                <PhysicalCharacterizationCBDTable header={'Physical Charac. CBD Table'}  eventGroupToTrigger={'compounds'}/>
                                <PharmacoDynamicsTable header={'Pharmaco Dynamics Table'}  eventGroupToTrigger={'compounds'}/>
                                <AromaTable header={'Aroma Table'}  eventGroupToTrigger={'compounds'}/>

                            </div>
                            <div className="col-3 col-sm-3">
                                <CompoundCard        header={'Compound'}  eventGroupToTrigger={'compounds'}   />
                                <CannabinoidCard     header={'Cannabinoid'} eventGroupToTrigger={'compounds'}  />
                                <MetaboliteCard     header={'Metabolite'} eventGroupToTrigger={'compounds'}  />
                                <TerpeneCard        header={'Terpene'}  eventGroupToTrigger={'compounds'}  />
                                <FlavonoidCard        header={'Flavonoid'}  eventGroupToTrigger={'compounds'}  />
                                <PhenolCard        header={'Phenol'}  eventGroupToTrigger={'compounds'}  />
                                <MarketCBDCard      header={'CBD Product'}  eventGroupToTrigger={'compounds'}  />
                                <PhysicalCharacterizationCard header={'Physical Charac. CBD'}  eventGroupToTrigger={'compounds'}  />
                                <PharmacoDynamicsCard header={'Pharmaco Dynamics'}  eventGroupToTrigger={'compounds'}  />
                                <AromaCard header={'Aroma'}  eventGroupToTrigger={'compounds'}  />
                            </div>
                        </div>
                    </TabPanel>

                    {/* Studies */}
                    <TabPanel forceRender={true}>
                        <div className="row" >
                            <div className="col-9 col-sm-9">
                                <TherapeuticIndicatorsTableCard header={'Therapeutic Indications'}  eventGroupToTrigger={'studies'}/>
                                <PKTableCard                    header={'PK'}                       eventGroupToTrigger={'studies'}/>
                                <PKIndicesTableCard             header={'PK Indices'}               eventGroupToTrigger={'studies'}/>
                                <PubchemClinicalTrialsTableCard header={'PUBCHEM - Clinical Trials'}  eventGroupToTrigger={'studies'}/>
                                <ClinicalTrialsTableCard  header={'Clinical Trials GOV'} eventGroupToTrigger={'studies'}/>

                            </div>
                            <div className="col-3 col-sm-3">
                                <ReferenceCard              header={'Reference'}      eventGroupToTrigger={'studies'}/>
                                <TherapeuticalIndicatorCard header={'Threapeutical Indications'} eventGroupToTrigger={'studies'} />
                                <PKCard                     header={'PK'}             eventGroupToTrigger={'studies'} />
                                <PKIndicesCard              header={'PK Indices'}     eventGroupToTrigger={'studies'} />
                                <ClinicalTrialCard          header={'Clinical Trial'} eventGroupToTrigger={'studies'} />
                            </div>
                        </div>
                    </TabPanel>

                    {/* External Databases */}
                    <TabPanel forceRender={true}>
                        <div className="row" >
                            <div className="col-9 col-sm-9">
                                <PubChemListCard    header={'PUBCHEM - Component/Substances List'} eventGroupToTrigger={'external'} />
                                <PubChemTableCard   header={'PUBCHEM - Properties'} eventGroupToTrigger={'external'} />
                                <ChemSpiderTableCard header={'ChemSpider - Components'} eventGroupToTrigger={'external'} />
                            </div>
                            <div className="col-3 col-sm-3">
                                <PubchemCard        header={'PUBCHEM Properties'}  eventGroupToTrigger={'external'}/>
                                <CannabisDBCard     header={'Cannabis DB'}  eventGroupToTrigger={'external'}/>
                                <ChemSpiderCard     header={'ChemSpider Properties'}  eventGroupToTrigger={'external'}/>
                            </div>
                        </div>
                    </TabPanel>

                    {/* Varieties / Strains */}
                    <TabPanel forceRender={true}>
                        <div className="row" >
                            <div className="col-9 col-sm-9">
                                <StrainsTable       header={'Varieties'} eventGroupToTrigger={'strains'} />
                            </div>
                            <div className="col-3 col-sm-3">
                                <StrainCard         header={'Variety'} eventGroupToTrigger={'strains'}/>
                                <StrainsCompoundsConcentrations header={'Concentration of terpenes in batch:'} eventGroupToTrigger={'strains'}/>
                            </div>
                        </div>
                    </TabPanel>

                    {/* Products */}
                    <TabPanel forceRender={true}>
                        <div className="row">
                            <div className="col-9 col-sm-9">
                                <ProductsAnalysisMethodTable header={'List of Analysis Methods'} eventGroupToTrigger={'products'} />
                                <ProductAnalysisValuesTable header={'List of Products Analysis Values'} eventGroupToTrigger={'products'} />
                                <ProductsAnalysisReportsTable header={'List of Analysis Reports'} eventGroupToTrigger={'products'} />

                            </div>
                            <div className="col-3 col-sm-3">
                                <ProductAnalisysMethodCard header={'Analysis Method'} eventGroupToTrigger={'products'} />
                                <ProductAnalisysReportCard header={'Analysis Report'} eventGroupToTrigger={'products'} />
                                <ProductAnalisysValueCard header={'Product Analysis'} eventGroupToTrigger={'products'} />

                            </div>
                        </div>
                    </TabPanel>


                    {/* Basket */}
                    <TabPanel forceRender={true}>
                        <div className="row" >
                             <AllBaskets />
                        </div>
                    </TabPanel>

                    {/* Loggers / Audit Trail */}

                        <TabPanel forceRender={true}>
                            <div className="row" >
                                { !isReadOnly && (

                                    <div className="col-9 col-sm-9">
                                    <UploadsCard header={'Bulk Imports'} eventGroupToTrigger={'loggers'} showBasket={false} storageValue={"uploads-logger"} showContent={true}/>
                                    <AuditTrailCard header={'Audit Trail'} eventGroupToTrigger={'loggers'} showBasket={false} storageValue={"audit-logger"}/>

                                </div>
                                )}

                                <div className="col-3 col-sm-3">
                                    <StadisticsCard header={'Stadistics'} eventGroupToTrigger={'loggers'} showBasket={false}  storageValue={"stadistics-logger"}/>
                                    <ChangeLog header={'Change Log'} eventGroupToTrigger={'loggers'} showBasket={false}  storageValue={"change-logger"}/>
                                </div>
                            </div>
                        </TabPanel>

                </Tabs>

            <div className="buttonOnTabs">


                <WizardSearch data={listsOfObjects} />

                { !isReadOnly && (<CreateData />)}

                <Tooltip title="Reset All data views" placement="top" arrow>
                    <Button
                        style={{ height: '32px',  boxShadow:'0px 0px 0px 0px'}}
                        variant="contained"
                        color="primary"
                        onClick={clearAll}
                        aria-label={"Reset All data views"}>
                       <HighlightOffIcon />
                    </Button>
                </Tooltip>
            </div>

            </ContextMessage>


        </div>
          </LocalizationProvider>
      </StorageProvider>
  );
}

export default App;
